import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const SubTitle = styled.div`
  margin-top: -22px;
`;

export { Wrapper, SubTitle };
