import React from 'react';
import ErrorContent from '../src/components/Pages/Error/Content';

export default function Custom404() {
  return (
    <ErrorContent
      title="404 error"
      subTitle="page not found"
      notice="Unfortunately, there is no such page or it has been removed, if you are sure that the link is correct"
    />
  );
}
