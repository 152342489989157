import * as React from 'react';
import Head from 'next/head';

import Router from 'next/router';

import Text from 'components/core/Text';

import DoubleTitle from '../../../DoubleTitle';

import {
  Wrapper,
  Section,
  ContentWrapper,
  ExternalLink,
  ButtonContainer,
  StyledButton,
} from './styles';

import Container from '../Container';

export default function ErrorContent({ title, subTitle, notice }) {
  return (
    <Wrapper>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <Section>
        <Container>
          <ContentWrapper>
            <DoubleTitle title={title} subTitle={subTitle} />
            <Text variant="textSmall" color="gray" mx="auto" mb={6}>
              {notice}, please{' '}
              <ExternalLink href="https://gmb.io/contact/">contact us</ExternalLink>
            </Text>
            <ButtonContainer>
              <StyledButton handleClick={() => Router.push('/logout')}>Sign In Again</StyledButton>
              <StyledButton
                handleClick={() => {
                  window.location.replace('/');
                }}
              >
                Return To Home
              </StyledButton>
            </ButtonContainer>
          </ContentWrapper>
        </Container>
      </Section>
    </Wrapper>
  );
}
