import * as React from 'react';

import Text from 'components/core/Text';
import { Wrapper, SubTitle } from './styles';

export default function Equipment({ title, subTitle }) {
  return (
    <Wrapper>
      <Text variant="promoHeader" color="grayExtraLight" isCondensed>
        {title}
      </Text>
      <SubTitle>
        <Text variant="display" color="black">
          {subTitle}
        </Text>
      </SubTitle>
    </Wrapper>
  );
}
