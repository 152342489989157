import styled from 'styled-components';
import colors from '../../../../constants/colors';

import Link from '../../../Link';
import Button from '../../../Button';

const Wrapper = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  width: 990px;

  @media screen and (max-width: 992px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const Section = styled.section`
  background: ${colors.main.white};
  border-radius: 2px;
  height: 440px;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: 992px) {
    overflow: hidden;
    height: 100vh;
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  width: 400px;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  width: max-content;
  transform: translateX(-50%);
  position: relative;
  left: 50%;

  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 300px;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

const ExternalLink = styled(Link)`
  color: ${colors.main.blue};
  text-decoration: underline;
`;

export { Wrapper, Section, ContentWrapper, StyledButton, ExternalLink, ButtonContainer };
